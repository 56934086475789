import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { EnvironmentProviders, NgZone, Provider } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthService, Browser } from 'ionic-appauth';
import {
  CapacitorBrowser,
  CapacitorSecureStorage,
} from 'ionic-appauth/lib/capacitor';
import { authFactory } from './auth.factory';
import { AuthInterceptor } from './auth.interceptor';
import { httpFactory } from './http/http.factory';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
  return [
    {
      provide: StorageBackend,
      useClass: CapacitorSecureStorage,
    },
    {
      provide: Requestor,
      useFactory: httpFactory,
      deps: [Platform, HttpClient],
    },
    {
      provide: Browser,
      useClass: CapacitorBrowser,
    },
    {
      provide: AuthService,
      useFactory: authFactory,
      deps: [Platform, NgZone, Requestor, Browser, StorageBackend],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ];
};
