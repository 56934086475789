import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'ionic-appauth';
import { Observable, from, lastValueFrom } from 'rxjs';
import { FeathersService } from 'src/services/feathers.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _feathersService: FeathersService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(this._feathersService.getBaseUrl())) {
      return from(this.handle(request, next));
    }

    return next.handle(request);
  }

  async handle(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    try {
      const token = await this._authService.getValidToken();
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }

    return await lastValueFrom(next.handle(request));
  }
}
