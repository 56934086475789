import { NgZone, inject } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController, Platform } from '@ionic/angular';
import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthActions, AuthService, Browser } from 'ionic-appauth';
import { environment } from 'src/app/app.environment';

export class AuthServiceWrapper extends AuthService {
  async removeToken(): Promise<void> {
    await this.storage.removeItem('token_response');
  }
}

export let authFactory = (
  platform: Platform,
  ngZone: NgZone,
  requestor: Requestor,
  browser: Browser,
  storage: StorageBackend
) => {
  const authService = new AuthServiceWrapper(browser, storage, requestor);
  const navController = inject(NavController);
  authService.authConfig = environment.oauthConfig;

  authService.events$.subscribe(async (event) => {
    switch (event.action) {
      case AuthActions.LoadTokenFromStorageSuccess:
        await authService.loadUserInfo();
        break;
      case AuthActions.SignInSuccess:
        await authService.loadUserInfo();
        await navController.navigateRoot('quickcheck');
        break;
      case AuthActions.SignInFailed:
        await authService.signOut();
        break;
      case AuthActions.SignOutSuccess:
        await browser.closeWindow();
        await authService.signIn();
        break;
    }
  });

  if (platform.is('capacitor')) {
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      if (!data.url) {
        return;
      }
      ngZone.run(() => {
        const callbackUrl = data.url;
        if (callbackUrl.indexOf(authService.authConfig.redirect_url) === 0) {
          authService.authorizationCallback(callbackUrl);
        } else if (
          callbackUrl.indexOf(
            authService.authConfig.end_session_redirect_url
          ) === 0
        ) {
          authService.endSessionCallback();
        }
      });
    });
  } else {
    authService.authConfig.redirect_url = window.location.origin + '/callback';
    authService.authConfig.end_session_redirect_url =
      window.location.origin + '/endsession';
  }

  authService.init();
  return authService;
};
