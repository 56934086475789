import { Capacitor } from '@capacitor/core';
import { IAuthConfig } from 'ionic-appauth';

type Environment = {
  baseApiUrl: string;
  oauthConfig: IAuthConfig;
  environment: string;
};

const config: Record<string, Omit<Environment, 'environment'>> = {
  local: {
    baseApiUrl: 'http://localhost:3030',
    oauthConfig: {
      client_id: '852ae224-369f-4ba3-92ed-5383fd14facc',
      server_host: `https://auth.halloalberta.de`,
      redirect_url: 'quickcheck://callback',
      end_session_redirect_url: 'quickcheck://endsession',
      scopes: 'openid offline_access',
      pkce: true,
    },
  },
  staging: {
    baseApiUrl: 'https://quickcheck.staging.k8s.api.halloalberta.de',
    oauthConfig: {
      client_id: '852ae224-369f-4ba3-92ed-5383fd14facc',
      server_host: `https://auth.halloalberta.de`,
      redirect_url: 'quickcheck://callback',
      end_session_redirect_url: 'quickcheck://endsession',
      scopes: 'openid offline_access',
      pkce: true,
    },
  },
  prod: {
    baseApiUrl: 'https://quickcheck.k8s.api.halloalberta.de',
    oauthConfig: {
      client_id: '852ae224-369f-4ba3-92ed-5383fd14facc',
      server_host: `https://auth.halloalberta.de`,
      redirect_url: 'quickcheck://callback',
      end_session_redirect_url: 'quickcheck://endsession',
      scopes: 'openid offline_access',
      pkce: true,
    },
  },
};

function getEnvironmentFromHost(): string {
  const { host } = window.location;
  console.log('host', host);
  if (host.startsWith('localhost')) {
    return 'local';
  }

  if (['quickcheck.staging.halloalberta.de'].includes(host)) {
    return 'staging';
  }

  // default to prod
  return 'prod';
}

const env = Capacitor.isNativePlatform() ? 'prod' : getEnvironmentFromHost();

export const environment: Environment = {
  baseApiUrl: config[env].baseApiUrl,
  oauthConfig: config[env].oauthConfig,
  environment: env,
};
