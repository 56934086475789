import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import feathers, { Application } from '@feathersjs/feathers';
import restClient from '@feathersjs/rest-client';
import { environment } from 'src/app/app.environment';

@Injectable({ providedIn: 'root' })
export class FeathersService {
  private _baseUrl = environment.baseApiUrl;
  private _app: Application;

  public get app(): Application {
    return this._app;
  }

  public getBaseUrl(): string {
    return this._baseUrl;
  }

  constructor(private _httpClient: HttpClient) {
    this._app = feathers();
    this._app.configure(
      restClient(this._baseUrl).angularHttpClient(this._httpClient, {
        HttpHeaders,
      })
    );
  }
}
