import { Injectable, inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Hospital } from 'src/models/hospital';
import { Insurance } from 'src/models/insurance';
import { InsuranceResult } from 'src/models/insurance-result';
import { ProductGroupAutocomplete } from 'src/models/product-group-autocomplete';
import { Regional } from 'src/models/regional';
import { SearchResult } from 'src/models/search-result';
import { FeathersService } from './feathers.service';

@Injectable({ providedIn: 'root' })
export class QuickcheckService {
  public get insuranceAutocomplete$() {
    return this._insuranceAutocomplete.asObservable();
  }

  public get productGroupAutocomplete$() {
    return this._productGroupAutocomplete.asObservable();
  }

  public get hospitalAutocomplete$() {
    return this._hospitalAutocomplete.asObservable();
  }

  private _insuranceDB: Insurance[];
  private _regionalDB: Regional[];
  private _insuranceResultDB: InsuranceResult[];
  private _hospitalDB: Hospital[];

  private _insuranceAutocomplete = new ReplaySubject<string[]>(1);
  private _productGroupAutocomplete = new ReplaySubject<
    ProductGroupAutocomplete[]
  >(1);
  private _hospitalAutocomplete = new ReplaySubject<any[]>(1);

  private _feathersService = inject(FeathersService);

  constructor() {}

  public searchInsurance(
    insuranceName: string,
    productGroupName: string,
    hospital?: { excludedLookups?: string[]; additionalInfos?: string }
  ): any {
    let searchResult = '';

    this._insuranceDB.forEach(({ name, productGroups }) => {
      if (name === insuranceName) {
        productGroups.forEach(({ shortName, hiMiPo, data }) => {
          const term = shortName + ' ' + hiMiPo;
          if (term === productGroupName) {
            searchResult = data;
          }
        });
      }
    });

    return Array.isArray(searchResult)
      ? searchResult.map((item) => this.parseInsuranceResult(item, hospital))
      : this.parseInsuranceResult(searchResult, hospital);
  }

  public searchRegional(insuranceName: string, postalCode: string) {
    let searchResult = '';

    this._regionalDB.forEach((item) => {
      if (item.name === insuranceName) {
        item.postalCodes.forEach((item) => {
          if (item.postalCode === postalCode) {
            searchResult = item.result;
          }
        });
      }
    });

    return this.parseRegionalResult(searchResult);
  }

  public async getInsuranceDB() {
    const response = (await this._feathersService.app
      .service('insurance')
      .find({})) as Insurance[];
    response.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    this._insuranceDB = response;
    this.getInsuranceAutocomplete(response);
    this.getproductGroupAutocomplete(response);
    this.getHospitalAutocomplete();
  }

  public async getInsuranceResultDB() {
    const response = (await this._feathersService.app
      .service('insurance-result')
      .find({})) as InsuranceResult[];
    this._insuranceResultDB = response;
  }

  public async getRegionalDB() {
    const response = (await this._feathersService.app
      .service('regional')
      .find({})) as Regional[];
    this._regionalDB = response;
  }

  public async getHospitalDB() {
    const response = (await this._feathersService.app
      .service('hospital')
      .find({})) as Hospital[];
    this._hospitalDB = response;
  }

  private parseInsuranceResult(
    searchString: string,
    hospital?: { excludedLookups?: string[]; additionalInfos?: string }
  ): SearchResult {
    const result = new SearchResult({
      type: 'warning',
      text: 'Keine Information vorhanden',
    });
    searchString = searchString.trim().toLowerCase();

    if (searchString) {
      const insuranceResult = this._insuranceResultDB.find(
        (f) => f._id === searchString
      );

      if (insuranceResult) {
        if (insuranceResult?.lookup?.length) {
          const lookupResult = insuranceResult.lookup.map((item) => {
            if (
              hospital?.excludedLookups &&
              hospital.excludedLookups.includes(item)
            ) {
              return '';
            }
            const result = this._insuranceResultDB.find((f) => f._id === item);
            return result?.text || '';
          });
          result.text = `${insuranceResult.text}\n${lookupResult.join('\n')}`;
          if (hospital?.additionalInfos) {
            result.text += `\n${hospital.additionalInfos}`;
          }
        } else {
          result.text = insuranceResult.text;
        }
        result.type = insuranceResult.type;
      }
    }

    return result;
  }

  private parseRegionalResult(searchString: string): SearchResult {
    const result = new SearchResult();
    searchString = searchString.trim().toLowerCase();
    switch (searchString) {
      case 'ja':
        result.type = 'accept';
        result.text = 'Versorgung möglich';
        break;
      case 'nein':
        result.type = 'error';
        result.text = 'Versorgung nicht möglich';
        break;
      default:
        result.type = 'warning';
        result.text = 'Keine Information vorhanden';
    }
    return result;
  }

  private getInsuranceAutocomplete(insuranceDB: Insurance[]): void {
    const autocomplete: string[] = [];

    insuranceDB.forEach((element) => {
      if (element.name && autocomplete.indexOf(element.name) === -1) {
        autocomplete.push(element.name);
      }
    });

    this._insuranceAutocomplete.next(autocomplete);
  }

  private getproductGroupAutocomplete(insuranceDB: Insurance[]): void {
    const autocomplete: ProductGroupAutocomplete[] = [];

    insuranceDB[0].productGroups.forEach(({ shortName, hiMiPo }) => {
      autocomplete.push({ shortName, hiMiPo });
    });
    this._productGroupAutocomplete.next(autocomplete);
  }

  private getHospitalAutocomplete(): void {
    const autocomplete: Hospital[] = [];

    (this._hospitalDB || []).forEach((element) => {
      autocomplete.push(element);
    });

    this._hospitalAutocomplete.next(autocomplete);
  }
}
