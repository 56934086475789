<div
    class="flex h-full flex-col items-center justify-between overflow-hidden border-r-[1px] pt-[--ion-safe-area-top] pl-[--ion-safe-area-left] pb-[--ion-safe-area-bottom] pr-[--ion-safe-area-right]">
    <div class="mt-5 w-10 h-10 md:w-14 lg:w-20">
        <img src="assets/icon/logo.svg" />
    </div>

    <ion-list class="flex flex-col w-full bg-transparent">
        <ion-button routerLink="quickcheck" class="m-0 p-0 border-t-[1px] border-b-[1px] border-slate-600">
            <ion-icon slot="icon-only" src="assets/icon/icons/checkbox.svg" class="py-3" />
        </ion-button>
        <ion-button routerLink="admin" class="m-0 p-0 border-b-[1px] border-slate-600" *ngIf="isAdmin">
            <ion-icon slot="icon-only" src="assets/icon/icons/cloud.svg" class="py-3" />
        </ion-button>
    </ion-list>

    <div class="flex flex-col w-full">
        <div class="m-1 text-[10px] text-center">Version: {{version}}</div>
        <ion-button (click)="logout()">
            <ion-icon slot="icon-only" src="assets/icon/icons/exit.svg" />
        </ion-button>
    </div>
</div>