import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { CapacitorHttpService } from './cap-http.service';
import { NgHttpService } from './ng-http.service';

export const httpFactory = (platform: Platform, httpClient: HttpClient) => {
  return platform.is('capacitor')
    ? new CapacitorHttpService()
    : new NgHttpService(httpClient);
};
