import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonButton, IonIcon, IonList } from '@ionic/angular/standalone';
import { AuthService } from 'ionic-appauth';
import { filter, take, tap } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.html',
  styles: `
      ion-button {
        --background: transparent;
        --box-shadow: none;
      }
    `,

  standalone: true,
  imports: [CommonModule, IonList, IonButton, IonIcon, RouterLink],
})
export class AppMenuComponent implements OnInit {
  @Input()
  public version: string = '';
  public isAdmin = false;

  ngOnInit(): void {
    this._authService.user$
      .pipe(
        filter((user) => user),
        take(1),
        tap((user) => (this.isAdmin = user.roles.includes('admin')))
      )
      .subscribe();
  }

  constructor(private _authService: AuthService) {}

  async logout(): Promise<void> {
    await this._authService.signOut();
  }
}
