import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthGuard } from 'src/core/auth/auth.guard';
import { QuickcheckService } from 'src/services/quickcheck.service';

export const routes: Routes = [
  {
    path: 'callback',
    loadComponent: () =>
      import('../pages/auth-callback/auth-callback.page').then(
        (m) => m.AuthCallbackPage
      ),
  },
  {
    path: 'endsession',
    loadComponent: () =>
      import('../pages/auth-end-session/auth-end-session.page').then(
        (m) => m.AuthEndSessionPage
      ),
  },
  {
    path: 'quickcheck',
    canActivate: [AuthGuard],
    resolve: {
      insurances: () => void inject(QuickcheckService).getInsuranceDB(),
      insuranceResult: () =>
        void inject(QuickcheckService).getInsuranceResultDB(),
      regional: () => void inject(QuickcheckService).getRegionalDB(),
      hospital: () => void inject(QuickcheckService).getHospitalDB(),
    },
    loadComponent: () =>
      import('../pages/quickcheck/quickcheck.page').then(
        (m) => m.QuickcheckPage
      ),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('../pages/admin/admin.page').then((m) => m.AdminPage),
  },
  {
    path: '',
    redirectTo: 'quickcheck',
    pathMatch: 'full',
  },
];
