import { Component, OnInit, inject } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import {
  IonApp,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  Platform,
} from '@ionic/angular/standalone';
import { AppMenuComponent } from 'src/core/menu/menu';
import packageJson from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styles: `
    ion-menu {
      --max-width: 5rem;
      --min-width: 5rem;
      @media (min-width: 640px) {
        --max-width: 7rem;
        --min-width: 7rem;
      }
      @media (min-width: 1024px) {
        --max-width: 8rem;
        --min-width: 8rem;
      }
    }
    ion-split-pane {
      --side-max-width: 5rem;
      --side-min-width: 5rem;
      @media (min-width: 640px) {
        --side-max-width: 7rem;
        --side-min-width: 7rem;
      }
      @media (min-width: 1024px) {
        --side-max-width: 8rem;
        --side-min-width: 8rem;
      }
    }
  `,
  standalone: true,
  imports: [IonApp, IonRouterOutlet, IonSplitPane, IonMenu, AppMenuComponent],
})
export class AppComponent implements OnInit {
  public versionInfo = packageJson.version;
  private _platform = inject(Platform);

  async ngOnInit(): Promise<void> {
    if (this._platform.is('capacitor')) {
      await StatusBar.setStyle({ style: Style.Default });
      await SplashScreen.hide();
    }
  }
}
